
.App {
    text-align: center;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    height: 100%;
    width: 100%;
    z-index: 0;
    margin-top: 5vh;
    /* --amplify-background-color:  */
    --amplify-primary-color: #4791ff !important;
    --amplify-primary-shade: #47a6ff !important;
    --amplify-primary-tint: #47a6ff !important;
}
amplify-greetings {
    --amplify-primary-color: #4791ff !important;
    --amplify-primary-shade: #47a6ff !important;
    --amplify-primary-tint: #47a6ff !important;
}

amplify-sign-out {
    --amplify-primary-color: #4791ff !important;
    --amplify-primary-shade: #47a6ff !important;
    --amplify-primary-tint: #47a6ff !important;
}

.authenticator-wrapper::after {
    content: "";


    background-image: url(/static/media/CyberCheck.df027d31.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 46% 18%; 
    opacity: 0.4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: 25%;
}

.welcome-text {
    color: white !important;
    margin-right: 2vw;
}

.rekognition-modal {
    min-width: 90vw;
    min-height: 90vh;
}
/* Tab setup for the modal page. */
.nav-tabs {
    flex-direction: row !important;
}
.navbar-nav .nav-link {
    padding-right: 2vw !important;
    padding-left: 2vw !important;
}
